import React, { Component } from 'react';

import Icon 				from '@mdi/react';

import { Link } 			from "react-router-dom";

import './Block.css';

class Block extends Component {

	constructor(props) {
        super(props);

        this.state = {
			touched: false
        };

    }
    
    toggleTouched = () => {
        const { touched } = this.state;
        
        this.setState({
			touched: !touched
        });

    }

    handleMouseUp = () => {
        /*
        setTimeout( () => {
            this.setState({ touched: false });
        }, 150);
        */
        this.setState({ touched: false });
    }
    
	render() {
        const { to, icon, label } = this.props;
        const { touched } = this.state;

		return (
            <Link to={to} onMouseDown={this.toggleTouched} onMouseUp={this.handleMouseUp}>
                <div className={"nav-button" + (touched ? ' down' : '' )} >
                    <Icon path={icon}
                        size={6}
                        color="#fff"
                    />
                    { label }
                </div>
            </Link>
		);

	}

}

export default Block;


