import { createStore } 			from 'redux';

import { 
	persistStore,
	persistCombineReducers
} 								from 'redux-persist';

import storage 					from 'redux-persist/lib/storage';


// Reducers
import app 						from './application/reducer';

let reducer = persistCombineReducers({
	key: 'kegasighin',
	storage
}, {
	app,

});

export default function configureStore() {

	const store = createStore(reducer);
	const persistor = persistStore(store);

	return { persistor, store }
}

