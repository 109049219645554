import axios                from 'axios';

import Monitor              from './monitor/Monitor';

class Webservice {

    constructor({url, headers = {}, timeout = 40000}) {

        this.api = axios.create({
            baseURL: url,
            timeout: timeout,
            headers: headers
        });

        this.monitor = new Monitor(this.api);

    }

    addHeader = (key, value) => {
        this.api.defaults.headers[key] = value;
    }

    removeHeader = (key) => {
        delete this.api.defaults.headers[key];
    }

    addMonitor = (monitor) => {
        this.monitor.add(monitor);
    }

    get = (path, params = {}, callback = null) => {

        this.api.get(path, {
            params: params,
        }).then((response) => {

            if (callback != null) {
                callback({
                    success: true,
                    data: response.data,
                    error: null
                });
            }

        }).catch((error) => {

            if (callback != null) {
                callback({
                    success: false,
                    data: error.data,
                    error: {
                        code: error.status,
                        name: 'Error',
                        message: (error.originalError ? error.originalError.message : 'Unkown')
                    }
                });
            }

        });

        return {
            cancel: () => {
                callback = null
            }
        }
         
    }

    post = (path, params = {}, callback = null, headers = {}) => {
   
        this.api.post(path, params, {headers: headers}).then((response) => {
        
            if (callback != null) {
                callback({
                    success: true,
                    data: response.data,
                    error: null
                });
            }

        }).catch((error) => {

            if (callback != null) {
                callback({
                    success: false,
                    data: error.data,
                    error: {
                        code: error.status,
                        name: 'Error',
                        message: (error.status === 422 ? error.data.message : error.originalError.message)
                    }
                });
            }

        });
     
        return {
            cancel: () => {
                callback = null
            }
        }

    }

    put = (path, params = {}, callback = null, headers = {}) => {

        this.api.put(path, params, {headers: headers}).then((response) => {
        
            if (callback != null) {
                callback({
                    success: true,
                    data: response.data,
                    error: null
                });
            }

        }).catch((error) => {

            if (callback != null) {
                callback({
                    success: false,
                    data: null,
                    error: {
                        code: error.status,
                        name: 'Error',
                        message: error.originalError.message
                    }
                });
            }

        });
        
        return {
            cancel: () => {
                callback = null
            }
        }

    }

    delete = (path, params = {}, callback = null, headers = {}) => {
   
        this.api.delete(path, params, {headers: headers}).then((response) => {
        
            if (callback != null) {
                callback({
                    success: true,
                    data: response.data,
                    error: null
                });
            }

        }).catch((error) => {

            if (callback != null) {
                callback({
                    success: false,
                    data: null,
                    error: {
                        code: error.status,
                        name: 'Error',
                        message: error.originalError.message
                    }
                });
            }

        });
        
        return {
            cancel: () => {
                callback = null
            }
        }
    }

    head = (path, params = {}, callback = null, headers = {}) => {
        
        this.api.head(path, {headers: headers}).then((response) => {

            if (callback != null) {
                callback(response);
            }

        }).catch((error) => {

            if (callback != null) {
                callback(error);
            }

        });

    }

}

export default Webservice;