import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import './Checkbox.css';

class Checkbox extends Component {

	constructor(props) {
        super(props);

        this.state = {
            checked: false,
			error: false
        };

    }

    onChange = (event) => {
        const { onChange } = this.props;
        const { checked } = this.state;

		this.setState({
            checked: !checked,
			error: false
		});

		if (onChange) {
			onChange(!checked);
		}
    }
    
    validate = () => {
		const { checked } = this.state;

		const error = !checked;

		this.setState({error: error});
		return !(error);
	} 
  
    render() {
        const { id, label, value, ...inputProps } = this.props;
        const { error } = this.state;

        return (
            <div className="m-checkbox">
                <input
                    type="checkbox"
                    className={"m-checkbox-input" +(error ? " m-checkbox-error-input" : "")}
                    ref={el => (this.selector = el)}
                    id={id}
                    value={value}
                    onChange={this.onChange}
                    {...inputProps}
                />
                <label className="m-checkbox-label" htmlFor={id}>{label}</label>
            </div>
        );

    }
}
  
Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    //label: PropTypes.isRequired,
};

Checkbox.defaultProps = {
    value: 1,
    label: 'Check box label'
};
  
export default Checkbox;