
import Webservice           from '../api/Webservice';

let instance = null;

class Visitors {

    constructor({url, token}) {
        
        this.url = url;

        this.api = new Webservice({
            url: url,
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        if(!instance){
            instance = this;
        }
    }

    static employees = (callback) => { instance.employees(callback); }
    employees = (callback) => {
        this.api.get('/employees', {}, callback);
    }

    static signedin = (callback) => { instance.signedin(callback); }
    signedin = (callback) => {
        this.api.get('/signedin', {}, callback);
    }

    static signin = (params, callback) => { instance.signin(params, callback); }
    signin = (params, callback) => {
        this.api.post('/signin', params, callback);
    }

    static signout = (params, callback) => { instance.signout(params, callback); }
    signout = (params, callback) => {
        this.api.post('/signout', params, callback);
    }

}

export default Visitors;