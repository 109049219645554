
class Monitor {

    constructor(axios) {

        const interceptors = axios.interceptors;

        this.monitors = [];
        
        /*
        interceptors.request.use((config) => {
            this.trigger(config);
            return config;
        }, (error) => {
            this.trigger(error);
            return Promise.reject(error);
        });
        */

        interceptors.response.use((response) => {
            
            const new_response = this.cleanResponse(response);
            this.trigger(new_response);

            return new_response;
        }, (error) => {

            const new_error = this.cleanResponse(error);
            this.trigger(new_error);

            return Promise.reject(new_error);
        });

    }

    trigger = (response) => {

        this.monitors.forEach((monitor) => {
            try {
              monitor(response)
            } catch (error) {

            }
        });

    }

    add = (monitor) => {
        this.monitors.push(monitor);
    }

    cleanRequest = (config) => {

        if (config === undefined) {
            return {}
        }

        return {
            method: config.method,
            baseURL: config.baseURL,
            url: config.url,
            timeout: config.timeout,
            headers: config.headers,
            data: config.data
        };
    
    }

    cleanResponse = (response) => {

        const error = (response instanceof Error);
        
        let originalError = null;
        if (error) {
            originalError = response;
            
            if (response.message !== 'Network Error') {
                response = response.response;
            } else {
                if (response.request._response) {
                    response.message += ', '+response.request._response;
                }
            }
            
        }

        const request = (response && response.config ? this.cleanRequest(response.config) : {});
 
        const status = (response && response.status) || null;

        const success = (status != null && status >= 200 && status <= 299);
        const headers = (response && response.headers) || null;
        const data = (response && response.data) || null;

        return {
            success: success,
            status: status,
            headers: headers,
            data: data,
            request: request,
            originalError : originalError 
        };

    }

}

export default Monitor;

