import React, { Component } from 'react';

import { connect }          from 'react-redux';

import { 
	addVisitors,
	setLanguage
}                           from '../../redux/application/actions';

import Header				from '../../common/header/Header';
import Back					from '../../common/back/Back';

import AutoInput			from '../../components/autoinput/AutoInput';
import Button 				from '../../components/button/Button';

import Visitors             from '../../lib/visitors/Visitors';
import Translations			from '../../lib/translations/Translations';

import './Signout.css';

class Signout extends Component {

	constructor(props, context) {
        super(props);

        this.state = {
			visitor: null,
			done: false,
			error: false,
			error_message: ''
		};
		
		this.timeout = null;

	}

	onVisitor = (visitor) => {
		this.setState({visitor: visitor});
	}

	onSubmit = (event) => {
		const { visitor } = this.state;

		this.setState({
			error: false,
			error_message: ''
		});

		let valid = true;
		if (visitor === null) { valid = false; }

		if (valid) {
	
			const data = {
				visitor_id: visitor.id,
			}
		
			Visitors.signout(data, (response) => {
				const { success, error } = response;

				if (success) {
					this.setState({done: true});
					
					Visitors.signedin((response) => {
						const { success, data } = response;
						if (success) {
							const { dispatch } = this.props;
							dispatch(addVisitors(data));
						}
					});

				} else {
					this.setState({
						error: true,
						error_message: error.message
					});
				}
			});
		}

		event.preventDefault();
		
	}
	
	shouldComponentUpdate(nextProps, nextState) {
		return (nextState.done !== this.state.done || nextState.error !== this.state.error);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevState.done && this.state.done) {
			this.timeout = setTimeout(() => {
				this.props.dispatch(setLanguage('nl'));
				this.props.history.push('/')
			}, 7000);
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	render() {
		const { visitors, language } = this.props;
		const { done, error, error_message } = this.state;

		return (
			<div id="signout">
				<div className={(done ? 'background' : 'background')}>
					<Header />
					<Back />
					{
						!done
						?
						<form className="form" autoComplete="off" onSubmit={this.onSubmit}>
								
							{
								error
								&&
								<p className="shadow"><b>{Translations.get('error_message', language)}:</b> {error_message} </p>
							}

							<AutoInput 
								name="lastname" 
								placeholder={Translations.get('lastname', language)}
								searchlength={3}
								suggestions={ visitors }
								onSelected={this.onVisitor}
							/>
							<Button name={Translations.get('signout', language)} />

						</form>
					:
						<React.Fragment>
						{
							language === 'nl'
							?
								<div className="thanx-text">
									<h1>Bedankt</h1>
									<p>Je bent nu afgemeld.<br />Vergeet niet je badge in te leveren, een goede reis.</p>
								</div>
							:
								<div className="thanx-text">
									<h1>Thanks</h1>
									<p>Thank you! You're signed out. <br /> Don't forget to return your visitor badge. Have a safe trip!</p>
								</div>
						}
						</React.Fragment>
					
					}
				</div>
				
				<img src={require('../../images/iso-logo.png')} className="logo" />

			</div>
		);

	}

}

function mapStateToProps(state, props) {	
    const { visitors, language } = state.app;

	return {
		language: language,
        visitors: visitors,
	};
}

export default connect(mapStateToProps)(Signout);