import React, { Component } from 'react';

import Autosuggest 			from 'react-autosuggest';

import Icon 				from '@mdi/react';
import { 
	mdiAccount
} 							from '@mdi/js';

import './AutoInput.css';

class AutoInput extends Component {

    constructor(props, context) {
        super(props);

        this.state = {
            value: '',
			suggestions: [],
			error: false
        };

    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
	};

	validate = () => {
		const { value } = this.state;

		const error = (value === '');

		this.setState({error: error});
		return !(error);
	} 

	onSuggestionSelected = (event, { suggestion }) => {
		const { onSelected } = this.props;
		this.setState({error: false});
		if (onSelected) {
			onSelected(suggestion);
		}

	}

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
	};
	  
	getSuggestions = (value) => {
		const { suggestions, searchlength } = this.props;

		if (value.length < searchlength ) { return []; }

		const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		if (escapedValue === '') {
		  return [];
		}
	  
		const regex = new RegExp('\\b' + escapedValue, 'i');
		
		return suggestions.filter((suggestion) => { 
			return regex.test(`${suggestion.firstname} ${suggestion.lastname}`);
		});
	}
	  
	getSuggestionValue = (suggestion) => {
		const { firstname, lastname, infix='' } = suggestion;

		const fullname = firstname + (infix !== '' ? ' ' + infix + ' ' : ' ') + lastname;

		return fullname;
	}
	  
	renderSuggestion = (suggestion, { query }) => {
		const { firstname, lastname, infix='' } = suggestion; //, photo=null

		const photo = null;

		const fullname = firstname + (infix !== '' ? ' ' + infix + ' ' : ' ') + lastname;

		return (
			<div className="suggestion">
				{
					photo != null
					?
						<img alt={fullname} className="photo" src={photo} />
					:
						<Icon path={mdiAccount}
							size={2}
							color="#dd005c"
						/>
				}
				<div className="name">{ fullname }</div>
			</div>
		);
	}

	render() {   
        const { value, suggestions, error } = this.state;
		const { placeholder } = this.props;

		return (
			<div>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					onSuggestionSelected={this.onSuggestionSelected }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={{
						placeholder: placeholder,
						value,
						onChange: this.onChange
					}}

					theme={{
						input: 					  'react-autosuggest__input' + (error ? ' error' : ''),
						container:                'react-autosuggest__container',
						containerOpen:            'react-autosuggest__container--open',
						inputOpen:                'react-autosuggest__input--open',
						inputFocused:             'react-autosuggest__input--focused',
						suggestionsContainer:     'react-autosuggest__suggestions-container',
						suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
						suggestionsList:          'react-autosuggest__suggestions-list',
						suggestion:               'react-autosuggest__suggestion',
						suggestionFirst:          'react-autosuggest__suggestion--first',
						suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
						sectionContainer:         'react-autosuggest__section-container',
						sectionContainerFirst:    'react-autosuggest__section-container--first',
						sectionTitle:             'react-autosuggest__section-title'
					}}
                />
			</div>
		);

	}

}

AutoInput.defaultProps = {
	searchlength: 1,
	placeholder: ''
}

export default AutoInput;


