
export default {

    signin: 'Aanmelden',
    signout: 'Afmelden',

    error_message: 'Foutemelding',
    appointment_with: 'Afspraak met',
    firstname: 'Uw voornaam',
    lastname: 'Uw achternaam',
    company: 'Bedrijf',
    
}