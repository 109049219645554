
export default {

    signin: 'Sign in',
    signout: 'Sign out',

    error_message: 'Error message',
    appointment_with: 'Appointment with',
    firstname: 'Your firstname',
    lastname: 'Your lastname',
    company: 'Company'

}