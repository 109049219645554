import React, { Component } from 'react';

import { 
	mdiAccount,
	mdiAccountOffOutline
} 							from '@mdi/js';

import { connect }          from 'react-redux';

import {
	setLanguage
}							from '../../redux/application/actions';

import Header				from '../../common/header/Header';

import Translations			from '../../lib/translations/Translations';

import Block 				from './block/Block';

import './Main.css';

class Main extends Component {

	constructor(props) {
		super(props);

		const { dispatch } = props;

		dispatch(setLanguage('nl'));
	}

	switch = (lang) => {
		const { dispatch } = this.props;
		dispatch(setLanguage(lang));
	}

	render() {
		const { language } = this.props;

		return (
			<div id="main">
				<Header />
				<div className="buttons">
					
					<Block 
						icon={mdiAccount}
						to="/signin"
						label={Translations.get('signin', language)}
					/>

					<Block 
						icon={mdiAccountOffOutline}
						to="/signout"
						label={Translations.get('signout', language)}
					/>

				</div>
				
				<img src={require('../../images/iso-logo.png')} className="logo" />

				<div className="language_switch">
					<button onClick={this.switch.bind(null, 'nl')}><img width="50" height="auto" src={require('../../images/nl.svg')} className="flag"/></button>
					<button onClick={this.switch.bind(null, 'en')}><img width="50" src={require('../../images/gb.svg')} className="flag"/></button>
				</div>

			</div>
		);

	}

}

function mapStateToProps(state, props) {	
    const { language } = state.app;

	return {
        language: language,
	};
}


export default connect(mapStateToProps)(Main);