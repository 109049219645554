import React, { Component } from 'react';

import './Button.css';

class Button extends Component {

	render() {
        const { name, onClick } = this.props;

		return (
			<input type="submit" value={name} className="button" name={name} onClick={onClick}  />
		);

	}

}

Button.defaultProps = {
	onClick: () => {}
}

export default Button;


