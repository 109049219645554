import React, { Component } from 'react';

import './Input.css';

class Input extends Component {

	constructor(props) {
        super(props);

        this.state = {
			value: '',
			error: false
        };

	}
	
	onChange = (event) => {
		const { onChange } = this.props;
		
		this.setState({
			value: event.currentTarget.value,
			error: false
		});

		if (onChange) {
			onChange(event.currentTarget.value);
		}
	}

	validate = () => {
		const { pattern } = this.props;
		const { value } = this.state;

		let error = false;

		if (pattern) {
			error = !RegExp(pattern).test(value)
		} else {
			error = (value === '');
		}		

		this.setState({error: error});
		return !(error);
	} 

	render() {
		const { name, placeholder } = this.props;
		const { error } = this.state;

		return (
			<input 
				type="text" 
				className={'input' + (error ? ' input_error' : '')}
				autoComplete="off"
				name={name} 
				placeholder={placeholder} 
				onChange={this.onChange}
			/>
		);

	}

}

export default Input;


