
import nl                   from './languages/nl';
import en                   from './languages/en';

class Translations {

    constructor() {


        this.data = {
            nl: nl,
            en: en
        }   

        this.language = 'nl';

    }

    setLanguage = (lang) => {
        this.language = lang;
    }

    get = (key, lang = this.language) => {

        if (this.data.hasOwnProperty(lang) && this.data[lang].hasOwnProperty(key)){
            return this.data[lang][key]
        }else{
            return key;
        }
        
    }

    format = (key, format_value, lang = this.language) => {

        if (this.data.hasOwnProperty(lang) && this.data[lang].hasOwnProperty(key)){

            let text = this.data[lang][key];
            Object.keys(format_value).forEach((key) => {
                text = text.replace(new RegExp("\\{" + key + "\\}", 'g'), format_value[key]);
            });
                
            return text;
        }else{
            return key;
        }
    }

}

export default new Translations();