import React, { Component } from 'react';
import { 
    BrowserRouter,
    Route, 
	Switch,
} 							from 'react-router-dom';

import { connect }          from 'react-redux';

import { 
    addEmployees,
    addVisitors
}                           from './redux/application/actions';

import Main					from './module/main/Main';
import Signin               from './module/signin/Signin';
import Signout              from './module/signout/Signout';

import Visitors             from './lib/visitors/Visitors';

class Container extends Component {

    constructor(props, context) {
        super(props);

        const visitors = new Visitors({
            url: 'https://bezoekers.kega.nl/api/v1',
            token: '15bb35bfd626cf82887101b513c7e91f6e7d6ba7701314badf88b2911b1db916'
        });

        visitors.employees((response) => {
            const { success, data } = response;
            if (success) {
                const { dispatch } = props;
                dispatch(addEmployees(data));
            }
        });

        visitors.signedin((response) => {
            const { success, data } = response;
            if (success) {
                const { dispatch } = props;
                dispatch(addVisitors(data));
            }
        });

    }

	render() {

		return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Main} />
					<Route path="/signin" component={Signin} />
					<Route path="/signout" component={Signout} />
                </Switch>
            </BrowserRouter>
		);

	}
	
}

export default connect()(Container);