import React, { Component } from 'react';

import { Provider }         from 'react-redux';
import { PersistGate } 		  from 'redux-persist/integration/react'

import configureStore       from './redux/store';

import Container            from './Container';

import './App.css';

const { persistor, store } = configureStore();

class App extends Component {

	render() {

		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Container />
				</PersistGate>
		  	</Provider>
		);
		
	}
	
}

export default App
