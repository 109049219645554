
import React, { Component } from 'react';

import { Link } 			from "react-router-dom";

import { 
	mdiArrowLeftCircle
} 							from '@mdi/js';

import Icon 				from '@mdi/react';

import './Back.css';

class Back extends Component {

	render() {

		return (
			<div id="back">
				<Link to="/" replace>
					<Icon path={mdiArrowLeftCircle}
						size={3}
						color="#dd005c"
					/>
				</Link>
			</div>
		);

	}

}

export default Back;


