
export function addEmployees(employees) {
	return {
		type: 'ADD_EMPLOYEES',
		employees: employees
	};
}

export function addVisitors(visitors) {
	return {
		type: 'ADD_VISITORS',
		visitors: visitors
	};
}

export function setLanguage(language) {
	return {
		type: 'SET_LANGUAGE',
		language: language
	};
}

