import React, { Component } from 'react';

import './Header.css';

class Header extends Component {

	render() {

		return (
			<div id="header">
				<svg width="340px" height="133px" viewBox="0 0 93 37" version="1.1" xmlns="http://www.w3.org/2000/svg" >
					<g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g id="footer" transform="translate(-1589.000000, -172.000000)" fill="#dd005c">
							<g id="Group-11" transform="translate(1589.000000, 172.000000)">
								<polygon id="Fill-1" fill="#a5a2a7" points="87.9134347 23 87 26.3552338 87 29 93 29 93 23"></polygon>
								<g id="Group-10">
									<path d="M74.5604492,24.8899583 C71.2096059,24.8899583 68.588358,22.3148165 68.588358,19.0161354 L68.588358,18.7259622 C68.588358,15.4271354 71.2096059,12.8519937 74.5604492,12.8519937 C77.9472823,12.8519937 80.4225232,15.3895528 80.4225232,18.8710488 C80.4225232,22.3523992 77.9472823,24.8899583 74.5604492,24.8899583 Z M80.5679449,8.86444252 L80.5679449,10.0526669 L81.3544509,12.7731866 L81.208883,12.8091669 C80.0320499,10.0526669 76.3716368,8.21097008 73.4307246,8.21097008 C68.0053546,8.21097008 63.8176781,12.8155764 63.8176781,18.8355055 L63.8176781,18.9065921 C63.8176781,24.9263756 68.0053546,29.5309819 73.4307246,29.5309819 C76.3716368,29.5309819 80.0320499,27.6894307 81.208883,24.932785 L81.3544509,24.9687654 L80.5679449,27.6894307 L80.5679449,28.8775094 L84.9741928,28.8775094 L84.9741928,8.86444252 L80.5679449,8.86444252 Z" id="Fill-2"></path>
									<path d="M45.557074,18.8351559 C45.557074,15.5363291 48.1059036,12.9241874 51.3828657,12.9241874 C54.6605594,12.9241874 57.2816609,15.6072701 57.2816609,19.0160772 C57.2816609,22.2068173 54.6605594,24.9263173 51.52858,24.9263173 C48.179346,24.9263173 45.557074,22.2429433 45.557074,18.8351559 Z M57.4276678,8.93561654 L57.4276678,10.1236953 L57.4705336,10.2718409 C57.5253959,10.8687937 58.0074527,12.1273764 58.2143201,12.8443606 L58.0686059,12.8801953 C57.9262565,12.5284039 57.7117814,12.1064 57.4174269,11.662837 L57.3556885,11.5470299 C57.2516695,11.4068961 57.1461876,11.2715693 57.0390964,11.1407583 C56.624191,10.6150378 56.0978055,10.0858213 55.4466265,9.62783701 C54.3904905,8.86933701 53.1941997,8.43932126 51.8229346,8.32089213 C51.7939673,8.31827008 51.7645611,8.31652205 51.7354475,8.31404567 C51.6400602,8.30690787 51.5448193,8.29933307 51.447969,8.29510866 C51.3073752,8.2882622 51.1648795,8.28418346 51.0193115,8.28418346 C45.3030981,8.28418346 40.7534768,12.9601677 40.7534768,18.8709906 C40.7534768,24.6358528 45.4486661,29.5653016 50.9473322,29.5653016 C52.9555852,29.5653016 54.5585886,28.8905614 56.2680981,27.2643094 L56.2692685,27.2653291 C57.0313425,26.608215 57.6522375,25.8354394 58.0223752,24.9684157 L58.1680895,25.0043961 L57.3922633,27.6874787 C56.4290275,30.6556362 54.1619707,32.3589472 51.0927539,32.3589472 C49.380611,32.3589472 47.7781928,31.6694945 46.5406454,30.3998409 L41.0817728,30.3998409 C42.6471773,34.4245378 46.5406454,36.9999709 51.0193115,36.9999709 C57.0639673,36.9999709 61.8337694,32.2859669 61.8337694,26.3036205 L61.8337694,8.93561654 L57.4276678,8.93561654 Z" id="Fill-4"></path>
									<path d="M28.790568,12.3443362 C31.3034079,12.3443362 33.0876764,13.9363559 34.1073838,16.8727575 L23.1475043,16.8727575 C24.2033477,14.0077339 26.2410069,12.3443362 28.790568,12.3443362 M28.608864,24.8899583 C26.2711446,24.8899583 24.2096386,23.3340646 23.3786575,21.006852 L22.5996127,18.3118244 L22.7451807,18.2758441 L24.1029862,20.5022535 L25.2452926,20.5022535 L39.1321859,20.5022535 C39.1681756,13.1417299 35.0176592,8.28424173 28.645,8.28424173 C22.892358,8.28424173 18.1578141,13.1417299 18.1578141,19.0161354 C18.1578141,24.8528126 22.8546127,29.5309819 28.718296,29.5309819 C33.0876764,29.5309819 37.1297849,26.5935606 38.3317814,22.6063008 L33.0521256,22.6063008 C31.9940878,24.1635055 30.5370912,24.8899583 28.608864,24.8899583" id="Fill-6"></path>
									<path d="M20.8661102,28.8447921 C20.8661102,28.8447921 11.03142,19.0204181 10.5882788,18.5752528 C11.0166437,18.1181425 19.7501377,8.8540126 19.7501377,8.8540126 L13.1314028,8.8540126 L6.07976764,16.9323945 L4.23215146,19.0488236 L4.12257315,18.9468551 L5.42844234,16.278922 C5.42844234,16.2407567 5.42844234,16.2039024 5.42844234,16.1673394 L5.42858864,16.167048 L5.42259036,0.13803622 L0,0.13803622 L0.0184337349,28.8469772 L5.4408778,28.8469772 L5.43414802,20.1774693 L13.4556024,28.8447921 L20.8661102,28.8447921 Z" id="Fill-8"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
		);

	}

}

export default Header;


