import React, { Component } from 'react';

import { connect }          from 'react-redux';

import { 
	addVisitors,
	setLanguage
}                           from '../../redux/application/actions';

import Header				from '../../common/header/Header';
import Back					from '../../common/back/Back';

import Input 				from '../../components/input/Input';
import AutoInput			from '../../components/autoinput/AutoInput';
import Button 				from '../../components/button/Button';
import Popup				from '../../components/popup/Popup';
import Checkbox				from '../../components/checkbox/Checkbox';

import Visitors             from '../../lib/visitors/Visitors';
import Translations			from '../../lib/translations/Translations';


import './Signin.css';

class Signin extends Component {

	constructor(props, context) {
        super(props);

        this.state = {
            employee: null,
			firstname: '',
			lastname: '',
			company: '',
			done: false, 
			error: false,
			error_message: ''
		};
		
		this.timeout = null;

    }

	onAppointment = (employee) => {
		this.setState({ employee: employee });
	}

	onFirstnameChange = (value) => {
		this.setState({ firstname: value });
	}

	onLastnameChange = (value) => {
		this.setState({ lastname: value });
	}

	onCompanyChange = (value) => {
		this.setState({ company: value });
	}

	onSubmit = (event) => {
		const { employee, firstname, lastname, company } = this.state;

		this.setState({
			error: false,
			error_message: ''
		});

		let valid = true;

		if (!this.appointment.validate()) { valid = false; }
		if (!this.firstname.validate()) { valid = false; }
		if (!this.lastname.validate()) { valid = false; }
		//if (!this.company.validate()) { valid = false; }
		if (!this.houserules_check.validate()) { valid = false; }
	
		if (valid) {
	
			const data = {
				firstname: firstname,
				lastname: lastname,
				company: company,
				appointment: employee.id,
				house_rules: true
			}
		
			Visitors.signin(data, (response) => {
				const { success, error } = response;

				if (success) {
					this.setState({done: true});
					
					Visitors.signedin((response) => {
						const { success, data } = response;
						if (success) {
							const { dispatch } = this.props;
							dispatch(addVisitors(data));
						}
					});

				} else {
					this.setState({
						error: true,
						error_message: error.message
					});
				}
			});
		}

		event.preventDefault();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (nextState.done !== this.state.done || nextState.error !== this.state.error);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevState.done && this.state.done) {
			this.timeout = setTimeout(() => {
				this.props.dispatch(setLanguage('nl'));
				this.props.history.push('/')
			}, 7000);
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	render() {
		const { employees, language } = this.props;
		const { done, error, error_message } = this.state;

		return (
			<div id="signin">
				<div className={(done ? 'background' : 'background')}>
					<Header />
					<Back />
					{
						!done
						?
						<form className="form" autoComplete="off" onSubmit={this.onSubmit}>

							{
								error
								&&
								<p className="shadow"><b>{Translations.get('error_message', language)}:</b> {error_message} </p>
							}
							
							<AutoInput 
								name="appointment" 
								ref={(input) => { this.appointment = input; }}
								placeholder={Translations.get('appointment_with', language)}
								suggestions={employees}
								onSelected={this.onAppointment}
							/>
							
							<div className="divider" />
							<Input 
								name="firstname" 
								placeholder={Translations.get('firstname', language)}
								ref={(input) => { this.firstname = input; }}
								onChange={this.onFirstnameChange}
								pattern=".{2,30}"
							/>
							<Input 
								name="lastname" 
								placeholder={Translations.get('lastname', language)}
								ref={(input) => { this.lastname = input; }}
								onChange={this.onLastnameChange}
								pattern=".{2,30}"
							/>
							<Input 
								name="company" 
								placeholder={Translations.get('company', language)}
								ref={(input) => { this.company = input; }}
								onChange={this.onCompanyChange}
							/>

							<Checkbox 
								id="houserules"
								ref={(input) => { this.houserules_check = input; }}
								label={
									language === 'nl'
									?
									<span className="shadow">Ik ga akkoord met de <a href="#" onClick={() => { this.houserules.show(); }}>huisregels</a> van Kega.</span>
									:
									<span className="shadow">I agree with Kega's <a href="#" onClick={() => { this.houserules.show(); }}>house rules</a>.</span>
								} 
							/>

							<div className="divider" />
							
							<Button name={Translations.get('signin', language)} />

						</form>
					:
						<React.Fragment>
						{
							language === 'nl'
							?
								<div className="thanx-text">
									<h1>Bedankt</h1>
									<p>Je afspraak wordt geinformeerd.<br />Vergeet niet je badge mee te nemen en na je bezoek weer af te melden.</p>
								</div>
							:
								<div className="thanx-text">
									<h1>Thanks</h1>
									<p>Your appointment will be automatically informed. <br /> Do not forget to take your badge with you and cancel it after your visit.</p>
								</div>
						}
						</React.Fragment>
						
					}
				</div>

				<Popup
					ref={(popup) => { this.houserules = popup;}}
				>
					{
						language === 'nl'
						?
							<React.Fragment>
								<h3>Kega huis- en veiligheidsregels bezoekers</h3>
								<ul>
									<li>Het is verplicht om de bezoekersbadge altijd zichtbaar te dragen</li>
									<li>Elke bezoeker neemt verantwoordelijkheid voor de veiligheid van zichzelf.</li>
									<li>Het is niet toegestaan om zonder Kega medewerker naar andere ruimtes en/of verdiepingen te lopen.</li>
									<li>Ons gebouw wordt 24-uur per dag bewaakt door bewakingscamera’s. Kega kan deze beelden laten observeren door de hiervoor bevoegde autoriteit.</li>
									<li>Het is niet toegestaan om beeld- en/of geluidsopnamen te maken in het Kega gebouw zonder toestemming.</li>
									<li>Gebruik het Kega Guests wifi netwerk. Het is niet toegestaan om direct gebruik te maken van Kega LAN zonder toestemming.</li>
									<li>In geval van calamiteiten, bel direct de receptie via <strong>1001</strong>.</li>
									<li>Bij vertrek, meld u weer af en lever de bezoekersbadge in.</li>
									<li><strong>Privacy note:</strong> uw gegevens worden alleen voor het daarvoor bestemde doel gebruikt en worden niet langer bewaard dan noodzakelijk.</li>
								</ul>
							</React.Fragment>
						:
							<React.Fragment>		
								<h3>Kega house- and safety rules visitors</h3>
								<ul>
									<li>It is compulsory that your visitor badge is visible at all times during your stay at Kega.</li>
									<li>Every visitor take responsible for their personal security.</li>
									<li>It is not permitted to go to other rooms or floors unsupervised by a Kega employee.</li>
									<li>Our building is 24 hours per day guarded by camera surveillance. Kega can let justice authorities observe the recordings made by these systems.</li>
									<li>Taking photographs and making video- or audio recordings in the Kega building are not allowed without permission.</li>
									<li>Use the Kega Guests wifi network. Your are not allowed to plugin to the Kega LAN directly without permission.</li>
									<li>In case of emergencies, call the front desk via <strong>1001</strong> immediately.</li>
									<li>When leaving, please sign out and return your visitor badge.</li>
									<li><strong>Privacy note:</strong> your personal data will only be used for the intended purpose and will not be stored longer than necessary.</li>
								</ul>
							</React.Fragment>
					}

					<div className="center margin-top-24">
						<Button name="Ok" onClick={() => {
							this.houserules.hide();
						}} />
					</div>

				</Popup>
				
				<img src={require('../../images/iso-logo.png')} className="logo" />

			</div>
		);

	}

}

function mapStateToProps(state, props) {	

    const { employees, language } = state.app;

	return {
		language: language,
        employees: employees,
	};
}

export default connect(mapStateToProps)(Signin);