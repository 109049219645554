
const initialState = {
    language: 'nl',
    employees: [],
    visitors: []
};

export default function app(state = initialState, action) {

    switch (action.type) {

        case 'ADD_EMPLOYEES':

            var { employees } = action;
            
            return Object.assign({}, state, {
                employees: employees
            });
    
        case 'ADD_VISITORS':

            var { visitors } = action;
            
            return Object.assign({}, state, {
                visitors: visitors
            });
        
        case 'SET_LANGUAGE':

            var { language } = action;
            
            return Object.assign({}, state, {
                language: language
            });

        default:
            return state;
        
    }
    
}


