import React, { Component } from 'react';

import './Popup.css';

class Popup extends Component {

    constructor(props, context) {
        super(props);

        this.state = {
            show: false
        };

    }

    show = () => {
        this.setState({show: true});
    }

    hide = () => {
        this.setState({show: false});
    }

    onWrapperClick = (event) => {
        if (event.nativeEvent.target.className === 'popup-wrapper') {
            this.hide();
        }
    }

	render() {
        const { children } = this.props;
        const { show } = this.state;

        if (!show) { return null; }

		return (
			<div className="popup-wrapper" onClick={this.onWrapperClick}>
                <div className="popup-container">
                    { children }
                </div>
            </div>
		);

	}

}

export default Popup;


